import { Component, ElementRef, OnInit  } from '@angular/core';
import { FormControl, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AuthService } from '@auth0/auth0-angular';
import { CustomerDto, CustomersClient, ImportFileColumnAttributeMetaData, ShipmentsClient } from '../../core/services/api.service';
import { DateTime } from 'luxon';
import { UserInfo, UserResolverService } from '../../core/services/user-resolver.service';
import { SpinnerService } from '../../core/spinner/spinner.svc';
import { SnackBarService } from '../../core/services/snack-bar.service';
import { MatTableModule } from '@angular/material/table';
import { MatExpansionModule } from '@angular/material/expansion';

import { MatIconModule } from '@angular/material/icon';
import { NgxMatFileInputModule } from '@angular-material-components/file-input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCardModule } from '@angular/material/card';

@Component({
    templateUrl: "./shipment-import.component.html",
    styleUrls: ["./shipment-import.component.scss"],
    standalone: true,
    imports: [FormsModule, MatCardModule, MatFormFieldModule, NgxMatFileInputModule, ReactiveFormsModule, MatIconModule, MatExpansionModule, MatTableModule]
})

export class ShipmentImportComponent implements OnInit {
  readonly accept: string = ".xlsx,.XLSX";
  minShipDate = DateTime.now().toUTC().startOf('day').toJSDate();
  fileControl: FormControl;
  file: File | undefined;
  accessToken: string = "";
  userInfo: UserInfo | undefined;
  customer: CustomerDto | undefined;
  columnData: ImportFileColumnAttributeMetaData[] = [];
  displayedColumns: string[] = ["name", "description", "isRequired", "requirements", "exampleValues"];
  displayIsProcessing: boolean = false;

  constructor(
    private _elementRef: ElementRef,
    private _authService: AuthService,
    private _shipmentClient: ShipmentsClient,
    private _spinnerService: SpinnerService,
    private _snackbarService: SnackBarService,
    private _userResolverService: UserResolverService,
    private _customerClient: CustomersClient
) {
  this.fileControl = new FormControl(null, [Validators.required]);

  this._userResolverService.userInfo.subscribe(x => {
      this.userInfo = x;
      if (x.CustomerId) {
        this._customerClient.customers_Get(x.CustomerId).subscribe(c => this.customer = c);
      }
    });
  }

  ngOnInit(): void {
    // gray background page
    this._elementRef.nativeElement.ownerDocument.body.style.backgroundColor = "#FAFAFA";

    // get access token
    this._authService.getAccessTokenSilently().subscribe(x => this.accessToken = x);
    this._shipmentClient.shipments_GetBulkShipmentImportColumns().subscribe(x => this.columnData = x);

    this.fileControl.valueChanges.subscribe((file: File) => {
      this._spinnerService.show();
      this._shipmentClient.shipments_ProcessShipmentImportFile({ data: file, fileName: file.name }).subscribe( {
        next: () => {
          this.displayIsProcessing = true;
          this._snackbarService.open("Your file is currently being processed and you will be notified by email when complete.");
        },
        complete: () => {
          this._spinnerService.hide();
        }
      });
    });
  }
}
