import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from "@angular/common/http";
import { ApplicationConfig, ErrorHandler, importProvidersFrom, provideZoneChangeDetection } from "@angular/core";
import { MAT_LUXON_DATE_ADAPTER_OPTIONS, MatLuxonDateModule } from "@angular/material-luxon-adapter";
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from "@angular/material/form-field";
import { BrowserModule } from "@angular/platform-browser";
import { provideAnimations } from "@angular/platform-browser/animations";
import { provideRouter } from "@angular/router";
import { AuthModule, AuthHttpInterceptor, AuthConfig } from "@auth0/auth0-angular";
import { provideNgxMask } from "ngx-mask";
import { environment } from "../environments/environment";
import { CoreModule } from "./core/core.module";
import { provideIcons } from "./core/providers/icons/icons.provider";
import { API_BASE_URL } from "./core/services/api.service";
import { ErrorInterceptor } from "./core/services/error.interceptor";
import { routes } from "./app.routes";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatDialogModule } from "@angular/material/dialog";
import { ApplicationinsightsAngularpluginErrorService } from "@microsoft/applicationinsights-angularplugin-js";
import { provideFuse } from "@fuse";

export function getAuthConfig(): AuthConfig {
  let config: AuthConfig = environment.auth;
  let windowUrl = window.location.href;

  if(windowUrl.indexOf('https://pr-') > -1) {
    let pr = windowUrl.split('.')[0].replace('https://', '');

    config.httpInterceptor?.allowedList.push({
      uri: `https://api-statusnow-portal-dev-${pr}.azurewebsites.net/*`,
      tokenOptions: {
        authorizationParams: { scope: "openid profile email" }
      }
    });
  }

  return config;
}

export const appConfig: ApplicationConfig = {
  providers: [
    provideZoneChangeDetection({ eventCoalescing: true }),
    importProvidersFrom(
      BrowserModule,
      CoreModule,
      AuthModule.forRoot(getAuthConfig()),

      //TODO: Investigate how to get rid of these. They're needed in various services (snackbar, shipmentschedule, etc.)
      MatSnackBarModule,
      MatDialogModule,
      MatLuxonDateModule
    ),

    {
      provide: ErrorHandler,
      useClass: ApplicationinsightsAngularpluginErrorService
    },

    {
        provide: API_BASE_URL,
        useFactory: () => {
            let apiUrl = environment.backendServerUrl;
            let windowUrl = window.location.href;
            if (windowUrl.indexOf('https://pr-') > -1) {
                let pr = windowUrl.split('.')[0].replace('https://', '');
                apiUrl = `https://api-statusnow-portal-dev-${pr}.azurewebsites.net`;
            }
            return apiUrl;
        },
    },
    {
        provide: HTTP_INTERCEPTORS,
        useClass: AuthHttpInterceptor,
        multi: true
    },
    {
        provide: HTTP_INTERCEPTORS,
        useClass: ErrorInterceptor,
        multi: true
    },
    {
        provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
        useValue: {
          appearance: 'outline'
        }
    },
    {
        provide: MAT_LUXON_DATE_ADAPTER_OPTIONS,
        useValue: { useUtc: true }
    },
    provideRouter(routes),
    provideHttpClient(withInterceptorsFromDi()),
    provideAnimations(),
    provideIcons(),
    provideNgxMask(),
    provideFuse({
      fuse: {
        layout: 'classy',
        scheme: 'light',
        screens: {
          sm: '600px',
          md: '960px',
          lg: '1280px',
          xl: '1440px',
        },
        theme: 'theme-default',
        themes: [ ],
      },
    }),
  ]
};
