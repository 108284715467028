<div class="font-sans bg-white rounded-xl p-4 realtime-incoming-shipments h-full">
  <div class="flex flex-col justify-center">
    <h2 class="mt-4 xl:text-2xl text-xl text-center leading-6 font-medium items-center text-gray-900">Inbound Shipments</h2>
    <div class="flex justify-center">
      <h4 class="xl:text-lg text-base text-gray-600 mt-2 font-normal hover:text-blue-500 cursor-pointer w-fit" (click)="editLocation()" matTooltip="Edit Receiving Location">
        <mat-icon class="align-text-bottom">location_on</mat-icon>
        {{selectedLocationDetails ?? "All Locations"}}
        <button type="button" class="ml-3">
          <i class="fas fa-edit"></i>
        </button>
      </h4>
      @if (selectedLocationDetails) {
        <button matTooltip="Clear Receiving Location" type="button" (click)="clearLocation()" class="ml-3 text-lg text-gray-600 mt-2 font-normal hover:text-blue-500 cursor-pointer ">
          <i class="fas fa-close"></i>
        </button>
      }
    </div>
    <sn-arrow-date-selector class="flex mt-4 justify-center" (onDateChange)="dateChanged($event)" [selectedDate]="selectedDate"></sn-arrow-date-selector>
  </div>
  <div #rowsContainer class="xl:mt-7 mt-5 flex justify-center flex-grow xl:text-[19px] text-[14px]">
    @if (!data) {
      <div class="flex items-center justify-center mt-20">
        <mat-spinner></mat-spinner>
      </div>
    }
    @for (dataChunk of dataSplit; track dataChunk) {
      <div class="xl:w-1/3 w-1/2 mx-2">
        <div class="list-group overflow-y-auto">
          @for (inboundShipment of dataChunk; track inboundShipment) {
            <a [routerLink]="['/shipments/inbound/filter', inboundShipment.carrierName, this.selectedDate.toFormat('MM-dd-yyyy')]" class="line-item block relative cursor-pointer py-3 px-5 border hover:bg-gray-300" matTooltip="{{inboundShipment.delivered}} of {{inboundShipment.total}} expected shipments delivered">
              <span class="block float-right link">{{inboundShipment.delivered}}/{{inboundShipment.total}}</span>
              <span class="list-group-progress bg-gray-300" [style.width]="(100 * inboundShipment.delivered / inboundShipment.total).toString() + '%'"></span>
              <div [style]="isMobile ? ('text-overflow: ellipsis; overflow: hidden; white-space: nowrap; max-width:' + ((windowWidth - 88) / dataSplit.length - 80) + 'px') : ''">{{inboundShipment.carrierName}}</div>
            </a>
          }
        </div>
      </div>
    }
    @if (data && data.length == 0) {
      <div class="flex text-lg items-center justify-center mt-20">
        <h1>No data available</h1>
      </div>
    }
  </div>
</div>
