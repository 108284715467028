import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { HostListener, Component, Inject, OnInit } from '@angular/core';
import { Notification, NotificationPriorityEnum } from '../../core/services/api.service';
import { UserResolverService } from '../../core/services/user-resolver.service';
import { RequestService } from '../../core/services/request.service';
import { DatePipe } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';

@Component({
    selector: 'sn-notification-details-modal',
    templateUrl: './notification-details-modal.component.html',
    standalone: true,
    imports: [MatDialogModule, MatButtonModule, DatePipe]
})
export class NotificationDetailsModalComponent implements OnInit {

  public notification: Notification | undefined;
  public notificationPriorityEnum = NotificationPriorityEnum;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<NotificationDetailsModalComponent>,
    public requestService: RequestService,
    public matDialog: MatDialog,
    protected _userResolverService: UserResolverService
  ) {

  }

  ngOnInit(): void {
    if (this.data) {
      this.notification = this.data.notification;
    }
  }

  @HostListener('window:keyup.esc') onKeyUp() {
    this.dialogRef.close();
  }
}
