<h4 mat-dialog-title [innerHTML]="notification?.title"></h4>
<mat-dialog-content>
    <div class="p-6 space-y-6 md:space-y-4 border rounded-md border-gray-300">
        <div class="flex flex-row">
            <label class="font-semibold mr-1">Priority:</label>
            <span>{{ notificationPriorityEnum[notification?.notificationPriority] }} </span>
        </div>
        <div class="flex flex-row">
            <label class="font-semibold mr-1">Date Published:</label>
            <span> {{notification?.publishedWhen.toJSDate() | date: 'MM/dd/yyyy hh:mm a z'}} </span>
        </div>
        <div [innerHTML]="notification?.content"></div>
    </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close class="dialog-footer-buttons">Close</button>
</mat-dialog-actions>
