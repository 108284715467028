import { Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogModule } from '@angular/material/dialog';
import { Notification, NotificationsClient } from '../../../core/services/api.service';
import { RequestService } from '../../../core/services/request.service';

@Component({
    selector: 'sn-terms-and-conditions-modal',
    templateUrl: './terms-and-conditions-modal.component.html',
    styleUrls: ['./terms-and-conditions-modal.component.scss'],
    standalone: true,
    imports: [MatDialogModule]
})
export class TermsAndConditionsModalComponent implements OnInit {

  notification!: Notification;

  constructor(
    @Inject(MAT_DIALOG_DATA) data: Notification,
    private _requestService: RequestService,
    private _notificationClient: NotificationsClient,
    protected dialogRef: MatDialogRef<TermsAndConditionsModalComponent>,
  ) {
    this.notification = data;
  }

  ngOnInit(): void {
  }

  acceptTermsConditions(): void {
    this._requestService.save(this._notificationClient.notifications_AcceptTermsAndConditionNotification(this.notification.id), () => {
      this.dialogRef.close();
    }, "Terms and Conditions")
  }
}
