import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { from, Observable, pipe, of } from 'rxjs';
import { PermissionCheckerService } from '../services/permission-checker.service';

@Injectable({
  providedIn: 'root'
})
export class AsmPermissionGuard  {
  constructor(private _permissionCheckerService: PermissionCheckerService) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    // If no permissions are supplied then do not allow access
    if (!next.data['permission']) return false;

    return new Promise<boolean>((res, rej) => {
      next.data['permission'].forEach(async (e: string, idx: number, arr: string | any[]) => {
        const hasAccess = await this._permissionCheckerService.isGranted(e);
        if (hasAccess) {
          res(hasAccess);
          return;
        }

        // Still no access in last item of the loop
        // then return false
        if (idx == arr.length - 1)
          res(false);
      });
    });
  }
}