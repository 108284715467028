import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ColumnTypeEnum, FilterItemDto, FilterTypeEnum, InboundShipmentByCarrierDto, LocationDto, LocationsClient, ShipmentsClient } from '../../core/services/api.service';
import { DateTime } from 'luxon';
import { RequestService } from '../../core/services/request.service';
import { UserSettingService, UserSettingsKeys } from '../../core/services/user-setting.service';
import { SpinnerService } from '../../core/spinner/spinner.svc';
import { SnackBarService } from '../../core/services/snack-bar.service';
import { MatDialog } from '@angular/material/dialog';
import { LocationSelectDialogComponent } from '../../shared/location-select-dialog/location-select-dialog.component';
import { LocationHelper } from '../../core/locations-helper';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ArrowDateSelectorComponent } from '../../shared/widgets/arrow-date-selector/arrow-date-selector.component';

import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
    templateUrl: './realtime-inbound-shipments.component.html',
    styleUrls: ['./realtime-inbound-shipments.component.scss'],
    standalone: true,
    imports: [MatTooltipModule, MatIconModule, ArrowDateSelectorComponent, MatProgressSpinnerModule, RouterLink]
})
export class RealtimeInboundShipmentsComponent implements OnInit, OnDestroy {
  _selectedDate: DateTime = DateTime.now().toUTC().set({second: 0, minute: 0, hour: 0, millisecond: 0});
  get selectedDate() {
    return this._selectedDate;
  }
  set selectedDate(value: DateTime) {
    if(this._selectedDate != value && value != null) {
      this._selectedDate = value;
      this.loadInboundShipments();
    }
  }

  data: InboundShipmentByCarrierDto[] = [];
  dataSplit: any = [];
  inboundShipmentsLocationID: number | null = null;
  selectedLocation?: LocationDto;
  selectedLocationDetails: string = "";

  intervalId: any = 0;
  rowCount: number = 12;
  isMobile: boolean = false;
  windowWidth: number = 0;

  currentSystemDate = DateTime.now();

  constructor(
    private _shipmentsClient: ShipmentsClient,
    protected _matDialog: MatDialog,
    private _snackBarService: SnackBarService,
    private _spinnerService: SpinnerService,
    private _userSettingService: UserSettingService,
    private _locationsClient: LocationsClient,
    private _dialog: MatDialog,
    private _requestService: RequestService,
    private _route: ActivatedRoute
  ) {
    // if mobile
    this.rowCount = Math.floor((window.innerHeight - 244) / 60);
    var self = this;
    window.addEventListener("orientationchange", function() {
      self.loadInboundShipments();
    });
  }

  ngOnInit(): void {
    this._spinnerService.show();

    let keys = this._route.snapshot.paramMap.keys;
    keys.forEach(param => {
      let value = this._route.snapshot.paramMap.get(param);
      switch (param) {
        case "date": this._selectedDate = DateTime.fromFormat(value ?? "", "MM-dd-yyyy");
      }
    });

    this._userSettingService.get(UserSettingsKeys.INBOUND_SHIPMENTS_WIDGET).subscribe({
      next: x => {
        if(x && x.value) {
          var locationFilter = JSON.parse(x.value);
          if(locationFilter) {
            this.inboundShipmentsLocationID = locationFilter.value1;
            if(this.inboundShipmentsLocationID) {
              this._requestService.get( this._locationsClient.locations_Get(this.inboundShipmentsLocationID), x => {
                this.setLocation(x);
              }, "Location");
            }
          }
          else {
            this.loadInboundShipments();
          }
        }

        this._spinnerService.hide();
      },
      error: err => {
        this._snackBarService.openError("Error user settings");
        this._spinnerService.hide();
      }
    });

    this.intervalId = setInterval(() => {
      // If the selected date is unchanged and the current system date moves to a different date, change the date
      if(this.currentSystemDate.startOf("day") == this.selectedDate.startOf("day") && this.currentSystemDate.startOf("day") != DateTime.now().startOf("day")) {
        this.currentSystemDate = DateTime.now();
        this._selectedDate = DateTime.now();
      }
      this.loadInboundShipments();
    }, 10 * 60 * 1000); // 10 minutes

  }

  ngOnDestroy() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }

  dateChanged(date: DateTime) {
    if(date) {
      this.selectedDate = date;
    }
  }

  loadInboundShipments() {
    this.data = [];
    this.dataSplit = [];
    let filterItems: FilterItemDto[] = [];

    if(this._selectedDate) {
      filterItems.push(FilterItemDto.fromJS({
        column: "scheduledDeliveryDate",
        value1: this._selectedDate,
        columnType: ColumnTypeEnum.Date,
        filterType: FilterTypeEnum.Equals
      }));
    }

    this._shipmentsClient.shipments_GetInboundShipmentsByCarrier(filterItems, this.inboundShipmentsLocationID).subscribe(y => {
      this.data = y;
      if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
        this.rowCount = Math.floor((window.innerHeight - 244) / 50);
        this.isMobile = true;
        this.windowWidth = window.innerWidth
      }
      this.dataSplit = [];
      for (let i = 0; i < this.data.length; i += this.rowCount) {
        this.dataSplit.push(this.data.slice(i, i + this.rowCount));
      }
    });
  }

  editLocation() {
    const locationSelect = this._dialog.open(LocationSelectDialogComponent, {
      width: '500px',
      disableClose: true
    });

    locationSelect.afterClosed().subscribe(result => {
      if (result) {
        this.setLocation(result);
      }
    });
  }

  setLocation(location: LocationDto | undefined) {
    this.selectedLocation = location ? location : undefined;
    this.selectedLocationDetails = location ? this.getLocationDetails(location) : "";
    this.inboundShipmentsLocationID = location ? location.id : null
    var locationFilter = null;

    if(this.inboundShipmentsLocationID) {
      locationFilter = FilterItemDto.fromJS({
        column: "destinationLocationID",
        value1: String(this.inboundShipmentsLocationID),
        columnType: ColumnTypeEnum.Number,
        filterType: FilterTypeEnum.Equals
      })
    }
    this._userSettingService.set(UserSettingsKeys.INBOUND_SHIPMENTS_WIDGET, JSON.stringify(locationFilter)).subscribe(x => {
      this.loadInboundShipments();
    });
  }

  clearLocation() {
    this.setLocation(undefined);
  }

  getLocationDetails(location: LocationDto) {
    return LocationHelper.locationAddressDisplay(location);
  }

  getLocationFilter():  FilterItemDto | null {
    let locationFilter = null
    if(this.inboundShipmentsLocationID) {
      locationFilter = FilterItemDto.fromJS({
        column: "destinationLocationID",
        value1: String(this.inboundShipmentsLocationID),
        columnType: ColumnTypeEnum.Text,
        filterType: FilterTypeEnum.Equals
      });
    }
    return locationFilter;
  }
}
