import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { throwIfAlreadyLoaded } from '../module-import-guard';
import { SpinnerComponent }   from './spinner.cmp';
import { SpinnerService }   from './spinner.svc';

@NgModule({
    imports: [CommonModule, SpinnerComponent],
    exports: [SpinnerComponent],
    providers: [SpinnerService]
})
export class SpinnerModule {
  constructor( @Optional() @SkipSelf() parentModule: SpinnerModule) {
    throwIfAlreadyLoaded(parentModule, 'SpinnerModule')
  }
}
