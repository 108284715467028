<button (click)="onOpenNotifications()" matTooltip="View Notifications" type="button"
  class="w-9 h-9 inline-flex justify-center items-center p-1 border border-transparent rounded-full shadow-sm text-black bg-gray-100 hover:bg-blue-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
  @if (!notifications || notifications?.length == 0) {
    <i class="fa-regular fa-bell fa-fw nav-bar-icon text-xl" id="notification-icon" title="Notifications"></i>
  }
  @if (notifications?.length > 0) {
    <i class="fa-solid fa-bell fa-fw nav-bar-icon text-xl" id="notification-icon" title="Notifications" [matBadge]="notifications?.length" matBadgeColor="warn"></i>
  }
</button>
<div class="z-20" role="dialog" aria-modal="true">
  <div id="notificationsModal"
    class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16 ease-in-out transition-all duration-300 z-20"
    [class.translate-x-0]="show" [class.translate-x-full]="!show">
    <div class="pointer-events-auto w-screen max-w-md">
      <div class="flex h-full flex-col overflow-y-auto bg-white shadow-xl">
        <div class="p-6">
          <div class="flex items-start justify-between">
            <h2 class="text-xl font-medium text-gray-800" id="slide-over-title">Notifications</h2>
            <div class="ml-3 flex h-7 items-center">
              <button (click)="show=false" type="button" class="group relative rounded-md bg-white text-gray-400 hover:text-gray-500 focus:ring-0">
                <span class="sr-only">Close panel</span>
                <i class="fa-light fa-xmark text-xl text-gray-400 group-hover:text-gray-500"></i>
              </button>
            </div>
          </div>
        </div>
        <div class="border-b border-gray-200">
          <div class="px-6">
            <nav class="-mb-px flex space-x-6">
              <a class="border-blue-500 text-blue-600 whitespace-nowrap pb-4 px-1 border-b-2 font-medium text-sm">All</a>
            </nav>
          </div>
        </div>
        <ul role="list" class="flex-1 divide-y divide-gray-200 overflow-y-auto">
          @if (!isLoading && (!notifications || notifications?.length == 0)) {
            <div
              class="flex flex-col justify-center items-center p-6 text-gray-800">
              <i class="fa-solid fa-circle-check text-blue-500 text-4xl mb-4"></i>
              <span class=" font-semibold text-xl">You're all caught up!</span>
              <p>When you get new notifications, they'll show up here.</p>
            </div>
          }
          @if (isLoading ) {
            <div>
              @for (item of [1,2]; track item) {
                <div class="group relative flex items-center py-6 px-5">
                  <div class="-m-1 block flex-1 p-1">
                    <div role="status" class="relative flex min-w-0 flex-1 items-center">
                      <div class="max-w-sm animate-pulse">
                        <div class="h-4 w-4 bg-gray-200 rounded-full  mb-4"></div>
                      </div>
                      <div class="w-full ml-4 line-clamp-3 animate-pulse">
                        <div class="h-4 w-full bg-gray-200 rounded-md max-w-[125px] mb-1"></div>
                        <div class="h-4 w-full bg-gray-200 rounded-md mb-1"></div>
                        <div class="h-4 w-full bg-gray-200 rounded-md max-w-[275px] mb-1"></div>
                        <div class="h-4 w-full bg-gray-200 rounded-md max-w-[90px] mb-1"></div>
                      </div>
                      <span class="sr-only">Loading...</span>
                    </div>
                  </div>
                  <div class="relative ml-2 inline-block flex-shrink-0 text-left">&nbsp;</div>
                </div>
              }
            </div>
          }
          @if (!isLoading) {
            @for (item of notifications; track item) {
              <li class="cursor-pointer">
                <div class="group relative flex items-center py-6 px-5">
                  <a (click)="onSelectNotification(item)" class="-m-1 block flex-1 p-1">
                    <div class="absolute inset-0 group-hover:bg-gray-50" aria-hidden="true"></div>
                    <div class="relative flex min-w-0 flex-1 items-center">
                      <span
                        class="relative inline-block flex-shrink-0">
                        @switch (item.notificationPriority) {
                          @case (notificationPriorityEnum.Normal) {
                            <i matTooltip="Normal Priority" class="fa-solid fa-circle text-xl text-blue-500"></i>
                          }
                          @case (notificationPriorityEnum.High) {
                            <i matTooltip="High Priority" class="fa-solid fa-circle text-xl text-yellow-500"></i>
                          }
                          @case (notificationPriorityEnum.Critical) {
                            <i matTooltip="Critical Priority" class="fa-solid fa-circle text-xl text-red-500"></i>
                          }
                        }
                      </span>
                      <div class="ml-4 line-clamp-3 w-[325px]">
                        <p class="truncate text-sm font-medium text-gray-800" [innerHTML]="item.title"></p>
                        <p class="line-clamp-2 text-sm text-gray-500" [innerHTML]="item.content"> </p>
                        <div class="text-xs text-blue-500 inline-flex"
                          matTooltip="{{ item.publishedWhen.toString() | dateTimeFromIso | dateTimeToFormat : 'MM/dd/yyyy hh:mm a ZZZZ' }}"
                          tooltipPosition="bottom" tooltipStyleClass="min-w-fit">
                          {{ item.publishedWhen.toString() | dateTimeFromIso | dateFromNow }}
                        </div>
                      </div>
                    </div>
                  </a>
                  <div class="relative ml-2 inline-block flex-shrink-0 text-left">
                    <button (click)="onCloseNotification(item)" type="button"
                      class="group relative inline-flex h-8 w-8 items-center justify-center rounded-full bg-white focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      id="options-menu-0-button" aria-expanded="false" aria-haspopup="true">
                      <span class="sr-only">Dismiss notification</span>
                      <span class="flex h-full w-full items-center justify-center rounded-full" matTooltip="Dismiss this notification">
                        <i class="fa-regular fa-circle-xmark text-gray-400 group-hover:text-gray-500"></i>
                      </span>
                    </button>
                  </div>
                </div>
              </li>
            }
          }
        </ul>
      </div>
    </div>
  </div>
</div>
