import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';

import { Notification, NotificationPriorityEnum, NotificationTypeEnum, NotificationsClient, SourceSystemEnum, NotificationDTO } from '../../core/services/api.service';
import { UserInfo, UserResolverService } from '../../core/services/user-resolver.service';
import { SnackBarService } from '../../core/services/snack-bar.service';

import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { NotificationDetailsModalComponent } from '../notification-details-modal/notification-details-modal.component';

import { environment } from "../../../environments/environment";
import { HubConnection, HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import { DateFromNowPipe } from '../pipes/formatDateFromNow';
import { LuxonModule } from 'luxon-angular';
import { MatBadgeModule } from '@angular/material/badge';

import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
    selector: 'sn-notification-slide-out',
    templateUrl: 'notification-slide-out.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [MatTooltipModule, MatBadgeModule, LuxonModule, DateFromNowPipe]
})
export class NotificationSlideOutComponent implements OnDestroy {

  private _hubConnection: HubConnection | undefined;

  public userInfo: UserInfo | undefined;
  public show: boolean = false;
  public notifications: Notification[] = [];
  public selectedNotification: NotificationDTO | undefined;
  public notificationPriorityEnum = NotificationPriorityEnum;

  public isLoading: boolean = false;
  public notificationModal: boolean = false;

  constructor(
    private _notificationsClient: NotificationsClient,
    private _userResolverService: UserResolverService,
    private _snackBarService: SnackBarService,
    private _matDialog: MatDialog,
    private _router: Router,
    private _cdr: ChangeDetectorRef,
    private _renderer: Renderer2
  ) {
    this._renderer.listen('window', 'click', (e: Event) => {
      let target = <any>e.target;

      if (target.id == "notification-icon-wrapper" || target.id == "notification-icon" || target.class == '') {
        return;
      }

      if (this.show) {
        if (!target.closest("#notificationsModal") && !target.closest('#notification-dialog') && !target.closest('#notification-confirm-dialog')) {
          this.show = false;
          this._cdr.detectChanges();
        }
      }
    });

    this._userResolverService.userInfo.subscribe({ next: res => this.userInfo = res })

    this.isLoading = true;
    this._notificationsClient
      .notifications_GetAllUserNotifications(NotificationTypeEnum.Automated)
      .subscribe({
        next: res => { this.notifications = res; this._cdr.detectChanges(); },
        error: err => console.log(err.error.error)
      })
      .add(() => this.isLoading = false);

    this.initializeSignalR();
  }

  public onOpenNotifications(): void {
    this.show = true;
  }

  public onSelectNotification(notification: NotificationDTO): void {
    this.selectedNotification = notification;

    if (!notification.viewedWhen) {
      this._notificationsClient
          .notifications_ViewNotification(notification.id, notification)
          .subscribe({
              next: res => {
                  if (res && this.selectedNotification) {
                      this.selectedNotification.viewedWhen = res.viewedWhen;
                  }
              }
          });
    }

    if(!notification.directLink) {
      this.notificationModal = true;
      this._matDialog.open(NotificationDetailsModalComponent, {
        width: '700px',
        data: { notification: notification },
        id: 'notification-dialog'
      });

      return;
    }

    if (notification.sourceSystem == SourceSystemEnum.Portal && notification.relativeLink) {
        this._router.navigate([`${notification.relativeLink}`]);
        this.show = false;
        this._cdr.detectChanges();
    }
    else {
        window.open(notification.directLink, '_blank');
    }
  }

  public onCloseNotification(notification: NotificationDTO): void {
    const confirmDialogRef = this._matDialog.open(ConfirmDialogComponent, {
      width: '375px',
      data: {
        title: "Dismiss Notification?",
        message: "Are you sure you want to hide this notification?",
        displayMessageAsHTML: false,
        okButtonText: "Yes",
        okButtonColor: 'primary',
        cancelButtonText: "No"
      },
      disableClose: true,
      id: 'notification-confirm-dialog'
    });

    confirmDialogRef.afterClosed().subscribe(result => {
      if (result) {

        this._notificationsClient
            .notifications_DismissNotification(notification.id, notification)
            .subscribe({
              next: res => {
                let index = this.notifications.findIndex(o => o.id == res.notificationId);
                if (index != -1) {
                  this.notifications.splice(index, 1);
                  this._cdr.detectChanges();
                }
              },
              error: err => console.log(err.error.error)
            })
      }
    });
  }

  public ngOnDestroy(): void {
    if(this._hubConnection) {
      this._hubConnection
      .invoke("removeFromNotificationsGroup")
      .then(() => {
        if(this._hubConnection) {
          this._hubConnection.stop();
          }
      });
    }
  }

  private initializeSignalR(): void {
    this._hubConnection = new HubConnectionBuilder()
      .withUrl(`${environment.stmBackendServerUrl}/hubs/notification`, { withCredentials: true })
      .withAutomaticReconnect()
      .withStatefulReconnect()
      .configureLogging(environment.production ? LogLevel.None : LogLevel.Debug)
      .build();

    this._hubConnection.on("onNewNotification", (response: Notification) => {
      if (response) {
        this._snackBarService.open('You have a new notification');
        this.notifications.unshift(response);
        this._cdr.detectChanges();
      }
    });

    this._hubConnection.on("log", res => { console.log(res); })

    this._hubConnection.start().then(() => {
      if (this._hubConnection) {
        this._hubConnection.invoke("joinNotificationGroup", this.userInfo?.Email);
        if (!environment.production) {
          console.log('Notification SignalR connected.');
        }
      }
    });
  }
}
